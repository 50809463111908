/* 将时间戳转化成年月日时分秒 */
const add0 = (m) => m < 10 ? '0' + m : m
export function format(value, formatStr) {
  //shijianchuo是整数，否则要parseInt转换
  let time = new Date(value)
  let y = time.getFullYear()
  let m = time.getMonth() + 1
  let d = time.getDate()
  let h = time.getHours()
  let mm = time.getMinutes()
  let s = time.getSeconds()
  if (formatStr === 'yyyy-MM-dd') return y + '-' + add0(m) + '-' + add0(d)
  if (formatStr === 'yyyy-MM-dd HH:mm:ss' || !formatStr) return y + '-' + add0(m) + '-' + add0(d) + ' ' + add0(h) + ':' + add0(mm) + ':' + add0(s)
  if (formatStr === 'MM-dd HH:mm:ss') return add0(m) + '-' + add0(d) + ' ' + add0(h) + ':' + add0(mm) + ':' + add0(s)
  if (formatStr === 'MM-dd') return add0(m) + '-' + add0(d)
}
export const emailReg = (value) => {
  const emailReg = /.+@[a-zA-Z0-9_-]+\.com/g   // 粗略匹配邮箱
  return emailReg.test(value)
}