<script setup>
import { onMounted, ref, watch, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import router from '@/router'
import { returnLanguage } from '../../assets/js/language'
import AboutBanner from './components/about-banner'
import HomeViewpoint from './components/home-viewpoint'
import { getList } from '@/api/new'

const store = useStore()
// 拿到vuex中的分类列表
const langtype = store.state.home.type
// eslint-disable-next-line no-undef
const langvalue = ref(returnLanguage(langtype))
// console.log(langvalue.value.top.one, '测试')

const checkedtype = ref(langvalue.value.news.two)
const type = ref(langtype)
const page = ref(0)
const limit = ref(4)
const productList = ref([])
const getNewList = () => {
  getList(page.value, limit.value, 3).then(res => {
    let {
      data: { count, list }
    } = res
    productList.value = list
  })
}
getNewList()

const goToInfo = id => {
  router.push({
    path: '/newInfo',
    query: {
      id: id,
      cateId: 3
    }
  })
}
/* 手动处理滚动 */
const handleScroll = () => {
  const scrollHeight = Math.max(
    document.documentElement.scrollHeight,
    document.body.scrollHeight
  )
  //滚动条滚动距离
  const scrollTop =
    window.pageYOffset ||
    document.documentElement.scrollTop ||
    document.body.scrollTop
  //窗口可视范围高度
  const clientHeight =
    window.innerHeight ||
    Math.min(document.documentElement.clientHeight, document.body.clientHeight)
  if (clientHeight + scrollTop >= scrollHeight) {
    console.log('请求数据')
  }
  // if (clientHeight + scrollTop >= scrollHeight && page.value <= total.value) {
  //   //快到底时----加载
  //   page.value++
  //   console.log('请求数据')
  //   // getProduct()
  // }
}

onMounted(() => {
  // getProduct(tabModule.activeType.value, tabModule.activeClass.value)
  window.addEventListener('scroll', handleScroll)
})
onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})
watch(
  () => store.state.home.type,
  newVal => {
    langvalue.value = returnLanguage(newVal)
    checkedtype.value = returnLanguage(newVal).news.two
    type.value = newVal
    getNewList()
  },
  { immediate: true }
)
</script>
<template>
  <div class="connew">
    <AboutBanner :checkedtype="checkedtype" title="NEWS" />
    <div class="new_main">
      <div class="title">
        <span class="line"></span>
        <span class="text">{{ langvalue.title.two }}</span>
        <span class="line"></span>
      </div>
      <div class="new_list">
        <div class="_content">
          <img class="new_img" src="../../assets/images/kx.png" />
          <div class="_list">
            <div
              class="item"
              v-for="(item, index) in productList"
              :key="index"
              @click="goToInfo(item.id)"
            >
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <HomeViewpoint />
  </div>
</template>

<style lang="less" scoped>
.connew {
  background: #f8f8f8;
  width: 100%;
  .title {
    font-size: 28px;
    font-weight: bold;
    color: #100e34;
    font-family: 'PingFang SC';
    font-size: 40px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 2px;
    .line {
      display: inline-block;
      width: 100px;
      height: 2px;
      background: #d3d5e0;
    }
    .text {
      margin: 0 10px;
    }
  }
  .new_main {
    width: 1240px;
    padding: 96px 0 200px;
    margin: 0 auto;

    ._content {
      display: flex;
      justify-content: space-between;
      padding-top: 80px;
      .new_img {
        width: 580px;
        height: 380px;
      }
      ._list {
        background: #fff;
        border-radius: 20px;
        padding: 30px 40px;
        .item {
          color: #000;
          font-size: 28px;
          font-weight: 600;
          width: 540px;
          padding: 14px 0;
          white-space: nowrap; /*不换行强制文本在一行显示*/
          overflow: hidden; /*超出盒子宽度部分文字被隐藏*/
          text-overflow: ellipsis; /*当文本溢出包含元素时发生的事情 ellipsis*/
          border-bottom: 1px solid #d3d5e0;
        }
      }
    }
  }
  .viewpoint {
  }
}
</style>
