// 封装购物车相关的API函数
import request from '@/utils/request'

/**
 * 获取新闻列表 - 产品 -科普 - 知识列表
 * // /api/web.Home/newsList?page=1&limit=1&cateid=3
 * 科普知识列表id：30，公司新闻id：6，行业新闻id：3，观点id：7，超算服务器id：5
 * @param {String} page - 页码
 * @param {String} limit - 数量
 * @param {String} cateid - 分类id
 */
export const getList = (page, limit, cateid) => {
  return request(
    `/api/web.Home/newsList?page=${page}&limit=${limit}&cateid=${cateid}`,
    'get'
  )
}
/**
 * https://admin.wukongbc.com/api/web.Home/goods
 * 
 */



export const getslList = (page, limit, cateid) => {
  return request(
    `/api/web.Home/goods`,
    'get'
  )
}

/**
 * 获取首页算力播报的参数
 * // /api/web.Home/computingPower
 */
export const getSl = () => {
  return request(`/api/web.Home/computingPower`, 'post')
}

/**
 * 添加联系方式
 * /api/web.Home/contactInfoAdd
 */
export const addConcat = data => {
  return request(`/api/web.Home/contactInfoAdd`, 'post', data)
}
/**
 * 添加联系方式
 * /api/web.Home/contactInfoAdd
 */
export const getInfo = params => {
  return request(`/api/web.Home/newsDetailList`, 'get', params)
}

/**
 * 添加联系方式
 * 
tp6/public/index.php/Server/lineChartSynchronize?
action=load_chart_price&currency_code=btc&history_days=30d&interval=30m
admin.wukongbc.com/api/web.Home/getHashrate?type=2&day=30
 */
export const getLine = params => {
  return request(`/api/web.Home/getHashrate`, 'get', params)
}

/**
 * 添加联系方式
 * /api/web.Home/MiningMachine?page=1&limit=1
 */
export const MiningMachine = params => {
  return request(`/api/web.Home/MiningMachine`, 'get', params)
}
