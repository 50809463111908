<template>
  <div class="home-tol">
    <div class="title">
      <span class="line"></span>
      <span class="text">{{ langvalue.title.three }}</span>
      <span class="line"></span>
    </div>
    <div class="detail">
      <div class="item" v-for="(item, index) in productList" :key="index" @click="goInfo(item.id)">
        <img :src="'https://admin.wukongbc.com/' + item.thumb" class="cover" />
        <p class="_title">{{ item.title }}</p>
        <p class="time">{{ format(item.create_time * 1000, 'yyyy-MM-dd') }}</p>
      </div>
    </div>
  </div>
</template>
<script setup>
import router from '@/router'
import { ref, watch } from 'vue'
import { useStore } from 'vuex'
import { format } from '@/utils/utils'
import { returnLanguage } from '../../../assets/js/language'
import { getList } from '@/api/new'
const store = useStore()
// 拿到vuex中的分类列表
const langtype = store.state.home.type
const type = ref(langtype)
// eslint-disable-next-line no-undef
const langvalue = ref(returnLanguage(langtype))
let productList = ref([])
const getNewList = () => {
  getList(1, 3, 7).then(res => {
    let {
      data: { count, list }
    } = res
    productList.value = list
  })
}
getNewList()
const goInfo = id => {
  router.push({
    path: '/newInfo',
    query: {
      id: id,
      cateId: 7
    }
  })
}
watch(
  () => store.state.home.type,
  newVal => {
    langvalue.value = returnLanguage(newVal)
    type.value = newVal
    getNewList()
  },
  { immediate: true }
)
</script>
<style scoped lang="less">
.home-tol {
  width: 100%;
  margin: 0 auto;
  padding: 100px 0 200px;
  // text-align: center;
  background: #f9f9f9;
}
.title {
  width: 1240px;
  margin: 0 auto;
  font-size: 40px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .line {
    display: inline-block;
    width: 100px;
    height: 2px;
    background: #d3d5e0;
  }
  .text {
    margin: 0 10px;
  }
}
.detail {
  width: 1240px;
  margin: 70px auto 0;

  display: flex;
  align-items: flex-start;
  .item {
    flex: 1;
    display: flex;
    flex-flow: column;
    justify-content: center;
    img {
      margin-bottom: 49px;
      width: 394px;
      height: auto;
    }

    ._title {
      padding-left: 20px;
      font-size: 20px;
      color: #000;
    }
    .time {
      padding-left: 20px;
      color: #a5a5a5;
      font-size: 18px;
    }
  }
  .item + .item {
    margin-left: 30px;
  }
}
</style>
